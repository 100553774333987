@import "~@angular/material/theming";
@include mat-core();
//@import "libs";

// Material colors
$mat-colors: (
  red: $mat-red,
  pink: $mat-pink,
  purple: $mat-purple,
  deep-purple: $mat-deep-purple,
  indigo: $mat-indigo,
  #006ce6: $mat-blue,
  light-blue: $mat-light-blue,
  cyan: $mat-cyan,
  teal: $mat-teal,
  green: $mat-green,
  light-green: $mat-light-green,
  lime: $mat-lime,
  yellow: $mat-yellow,
  amber: $mat-amber,
  orange: $mat-orange,
  deep-orange: $mat-deep-orange,
  brown: $mat-brown,
  grey: $mat-grey,
  blue-grey: $mat-blue-grey
);

// Colors shorthand
$ui-colors: (
  black: (
    base: black
  ),
  grey: (
    base: rgba(119, 119, 119, 1),
    boulder: rgba(117, 117, 117, 1),
    slate: rgba(122, 139, 147, 1),
    emperor: rgba(81, 70, 73, 1),
    gallery: rgba(238, 238, 238, 1),
    mercury: rgba(229, 229, 229, 1),
    concrete: rgba(242, 242, 242, 1),
    alabaster: rgba(250, 250, 250, 1)
  ),
  white: (
    base: white
  )
);
@mixin costomize-components($theme) {
  // Apply theme to ngx-datatable componenet
  // @include ngx-mat-data-table($theme);
}
/* *
 * Colors */
// Typography
$mat-typography: mat-typography-config(
  $font-family: "Roboto",
  $headline: mat-typography-level(24px, 48px, 400),
  $body-1: mat-typography-level(16px, 24px, 400)
);
// @include angular-material-typography($mat-typography);
/* * Material Themes */
// default Theme
$agero-primary: mat-palette($mat-blue, 700, 100, 800);
$agero-accent: mat-palette($mat-orange, 700, 100, 800);
$agero-warn: mat-palette($mat-red);

$agero-theme: mat-light-theme($agero-primary, $agero-accent, $agero-warn);
@include angular-material-theme($agero-theme);
.default-theme {
  @include angular-material-theme($agero-theme);
  @include costomize-components($agero-theme);
}
// Dark theme
$dark-primary: mat-palette($mat-blue-grey);
$dark-accent: mat-palette($mat-amber, A200, A100, A400);
$dark-warn: mat-palette($mat-deep-orange);
$dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
.dark-theme {
  @include angular-material-theme($dark-theme);
  @include costomize-components($dark-theme);
}
// Light theme
$light-primary: mat-palette($mat-grey, 200, 500, 300);
$light-accent: mat-palette($mat-brown, 200);
$light-warn: mat-palette($mat-deep-orange, 200);
$light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);
.light-theme {
  @include angular-material-theme($light-theme);
  @include costomize-components($light-theme);
}
